import { FC, useEffect } from "react";
import { Form, Space } from "antd";
import cn from "classnames";
import { useParams } from "react-router-dom";

import { ArrowRightOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, PageItemLayoutGroup } from "@ni/common/ui";
import { TenantApi } from "@ni/sdk/apis";
import { ChangeTenantRequest, Tenant } from "@ni/sdk/models";

import styles from "./styles.module.scss";

const tenantsServiceApi = new TenantApi();

interface AdditionServiceDetailsForm {
  "nic-falcon-enable-notif": boolean;
  isCardControlSelected: boolean;
  isFalconSelected: boolean;
}

export const AdditionalServicesDetails: FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");

  const setFalcon = (val: boolean) => {
    form.setFieldsValue({
      "nic-falcon-enable-notif": val,
      isFalconSelected: val,
    });
  };

  useEffect(() => {
    if (!isLoading) {
      let nicFalconEnableNotif = tenant.isFalconSelected;

      tenant?.tenantValues?.forEach(val => {
        if (val.fieldCode === "nic-falcon-enable-notif") {
          nicFalconEnableNotif = val.value?.toString() === "true";
        }
      });

      form.setFieldsValue({
        "nic-falcon-enable-notif": nicFalconEnableNotif,
        isCardControlSelected: tenant.isCardControlSelected,
        isFalconSelected: tenant.isFalconSelected,
      });
    }
  }, [form, isLoading, tenant]);

  const onFinish = (value: AdditionServiceDetailsForm) => {
    setIsLoading(true);
    const tenantId = parseInt(id ?? "0", 10);

    const requestBody: ChangeTenantRequest = {
      isCardControlSelected: value.isCardControlSelected,
      isFalconSelected: value.isFalconSelected,
      tenantValues: Object.keys(value).map(val => {
        if (val === "isCardControlSelected" || val === "isFalconSelected") {
          return {};
        }

        return {
          fieldCode: val,
          value: value[val as keyof AdditionServiceDetailsForm] ? "true" : "false",
        };
      }),
    };
    tenantsServiceApi
      .editTenant(requestBody, tenantId)
      .then(res => {
        setIsLoading(false);
        setTenant(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      pageTitle="Additional services details"
      form={form}
      submitHandler={onFinish as unknown as (values: FormValues) => void}
      formSize="lg"
      size="full"
      submitLabel="Save"
    >
      <div
        className={cn(
          styles["additional-services-details-container"],
          styles["additional-services-details-card-section"],
        )}
      >
        <div className={styles["additional-services-details-card-header"]}>
          <NetworkForm.Switch
            formItemOptions={{
              valuePropName: "checked",
              name: "isFalconSelected",
              label: "Falcon fraud monitoring",
            }}
          />
        </div>
        <div className={styles["additional-services-details-card-body"]}>
          <PageItemLayoutGroup>
            <div>
              Falcon is Network International’s peripheral system which provides fraud monitoring facilities. {"  "}
              <a
                href="https://www.network.ae/en/contents/listing/fraud-solutions#book-1"
                target="_blank"
                rel="noreferrer"
              >
                <span>Read more </span>
                <ArrowRightOutlined />
              </a>
            </div>
            <Space direction="horizontal" size={8}>
              <Form.Item dependencies={["isFalconSelected"]}>
                {() => (
                  <NetworkForm.Switch
                    disabled={!form.getFieldValue("isFalconSelected")}
                    onChange={setFalcon}
                    formItemOptions={{
                      className: styles["additional-services-details-form-item"],
                      valuePropName: "checked",
                      name: "nic-falcon-enable-notif",
                      label: "Enable Notifications",
                    }}
                  />
                )}
              </Form.Item>
            </Space>
          </PageItemLayoutGroup>
        </div>
      </div>
      <div
        className={cn(
          styles["additional-services-details-container"],
          styles["additional-services-details-card-section"],
        )}
      >
        <div className={styles["additional-services-details-card-header"]}>
          <NetworkForm.Switch
            formItemOptions={{
              className: styles["additional-services-details-form-item"],
              valuePropName: "checked",
              name: "isCardControlSelected",
              label: "Card Control",
            }}
          />
        </div>
        <div className={styles["additional-services-details-card-body"]}>
          <PageItemLayoutGroup>
            <div>
              Card control is Network International’s peripheral system which provides certain transactional level
              controls to the client and as well as to the customer. {"  "}
              <a
                href="https://www.network.ae/en/contents/listing/addonsolutions#mbook-1"
                target="_blank"
                rel="noreferrer"
              >
                <span>Read more </span>
                <ArrowRightOutlined />
              </a>
            </div>
          </PageItemLayoutGroup>
        </div>
      </div>
    </CustomFormWrapper>
  );
};
