import { FC, HTMLAttributes, ReactNode } from "react";
import { Form, Input, InputNumberProps, InputProps, Select, SelectProps } from "antd";
import { Rule } from "antd/es/form";
import { TextAreaProps } from "antd/lib/input";
import { DefaultOptionType } from "antd/lib/select";
import cn from "classnames";

import { NetworkForm, TooltipInfo } from "@ni/common/ui";
import { DashboardPredefinedValue, QpDashboard } from "@ni/sdk/models";

import { ParamCodeSelect, ParamCodeSelectProps } from "../ParamCodeSelect";

export type InputType = "number" | "text" | "textarea" | "select" | "paramCodeSelect" | "none";

interface ExtendedQpDashboard extends QpDashboard {
  [key: string]: number | string | DashboardPredefinedValue[] | undefined;
}

interface EditableCellProps extends HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: InputType;
  props?: unknown;
  formRules?: Rule[];
  record: ExtendedQpDashboard;
  children: ReactNode;
}

export const EditableCell: FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  props,
  formRules,
  record,
  children,
  ...restProps
}) => {
  const inputNode = (inputType: InputType) => {
    switch (inputType) {
      case "number":
        return <NetworkForm.Number {...(props as InputNumberProps)} className="w-p-100" />;

      case "text":
        return <Input {...(props as InputProps)} className="w-p-100" />;

      case "textarea":
        return (
          <Input.TextArea
            autoSize={{ minRows: 1, maxRows: 16 }}
            {...(props as TextAreaProps)}
            style={{ minWidth: "350px" }}
            className="w-p-100"
          />
        );

      case "select": {
        const { options, ...restProps } = props as {
          options: (DefaultOptionType & { tooltip: string; value: string | undefined })[];
          restProps: SelectProps;
        };

        return (
          <Select {...restProps} className="w-p-100">
            {options?.map(option => (
              <Select.Option key={`${(record as QpDashboard).id}-${option.value}`} value={option.value}>
                {option.tooltip ? (
                  <TooltipInfo label={option.label as string} tooltipProps={{ title: option.tooltip }} />
                ) : (
                  option.label
                )}
              </Select.Option>
            ))}
          </Select>
        );
      }

      case "paramCodeSelect": {
        const { fiCode, paramType } = props as ParamCodeSelectProps;
        return (
          <ParamCodeSelect
            {...(props as ParamCodeSelectProps)}
            fiCode={fiCode}
            paramType={paramType}
            className="w-p-100"
          />
        );
      }

      default:
        return <Input {...(props as InputProps)} className="w-p-100" />;
    }
  };

  return (
    <td {...restProps} className={cn(restProps.className, editing && "qp-editing")}>
      {editing && inputType !== "none" ? (
        <Form.Item
          name={`${(record as QpDashboard).id}@${dataIndex}`}
          initialValue={record?.[dataIndex]}
          className="m-b-0 w-p-100"
          rules={formRules}
          noStyle={true}
        >
          {inputNode(inputType)}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
