import * as jp from "jsonpath";

import { Tenant } from "@ni/sdk/models";

export const checkIfOneProductHasFieldCodeValue = (tenant: Tenant, fieldCode: string, value: string): boolean => {
  let values: number[] = [];
  if (tenant?.products?.length) {
    values = jp.query(
      tenant,
      `$.products[?(@.productValues)].productValues[?(@.fieldCode=='${fieldCode}'&&@.value=='${value}')]`,
    ) as number[];
  }

  return !!values.length;
};

const escalateQuery = (query: string, object: unknown, defaultValue: boolean): boolean => {
  if (query?.length) {
    if (query[0] === "!") {
      return !(jp.query(object, query.substring(1)) as boolean[])?.length;
    }
    return !!(jp.query(object, query) as boolean[])?.length;
  }
  return defaultValue;
};

const filterAlteredString = (query: string) => {
  let alteredString = query?.trim();
  if (alteredString.charAt(0) !== "$" && alteredString.charAt(0) === "(") {
    alteredString = query.slice(1, query.length - 1);
  }
  return alteredString;
};

export const validateJsonPath = (object: unknown, evaluationString: string | undefined): boolean => {
  if (object && evaluationString) {
    let evalResults: boolean[];
    if (/\bOR\b(?!.*\bAND\b)/.test(evaluationString)) {
      const queries = evaluationString.split("OR")?.map(filterAlteredString);
      evalResults = queries.map(query => escalateQuery(query, object, false));
      let finalResult = false;
      evalResults.forEach(result => {
        finalResult = finalResult || result;
      });
      return finalResult;
    }
    if (/\bAND\b(?!.*\bOR\b)/.test(evaluationString)) {
      const queries = evaluationString.split("AND")?.map(filterAlteredString);
      evalResults = queries.map(query => escalateQuery(query, object, true));
      let finalResult = true;
      evalResults.forEach(result => {
        finalResult = finalResult && result;
      });
      return finalResult;
    }
    return escalateQuery(evaluationString, object, true);
  }
  return true;
};
