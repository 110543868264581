import { FC, useMemo } from "react";
import { Form, Space } from "antd";
import { StoreValue } from "rc-field-form/lib/interface";
import { useParams } from "react-router-dom";

import { useGetPerso, useHydrateForm, useProductSettings, useReduxState } from "@ni/common/hooks";
import { nicCardCarrAddressLevPrim, nicCardCarrAddressLevSuppl } from "@ni/common/mocks";
import { FormValues } from "@ni/common/types";
import { Alert, CustomFormWrapper, FormItemLabel, NetworkForm } from "@ni/common/ui";
import { Tenant } from "@ni/sdk/models";

import {
  NIC_CARD_CARR_ADDRESS_LEV_PRIM,
  NIC_CARD_CARR_ADDRESS_LEV_SUPPL,
  NIC_CARD_SUBTYPE_AUTORENEW_DAYS,
  NIC_CARD_SUBTYPE_EXP_MONTHS,
  NIC_CARD_SUBTYPE_PINSET_ALLOWED,
  NIC_CARD_SUBTYPE_PINSET_ALLOWED_FOR_LOCKED,
  NIC_PREEMB_AUTO_ACTIVATE,
  REISS_PHYS,
  SDE,
} from "../../constants";

import {
  cardPersonalizationOptions,
  cardTypeOptions,
  OTHER_INFO_TEXT,
  persoSelectValue,
  REISS_PHYS_LABEL,
  SDE_INFO,
  SDE_LABEL,
  SDE_TOOLTIP,
} from "./constants";

import styles from "./styles.module.scss";

const PERSO_SUBSTANCE = "PERSO_SUBSTANCE";

const stringsKeys = [
  cardTypeOptions.code,
  NIC_CARD_SUBTYPE_EXP_MONTHS,
  NIC_CARD_SUBTYPE_AUTORENEW_DAYS,
  NIC_CARD_CARR_ADDRESS_LEV_PRIM,
  NIC_CARD_CARR_ADDRESS_LEV_SUPPL,
  REISS_PHYS,
  NIC_PREEMB_AUTO_ACTIVATE,
  NIC_CARD_SUBTYPE_PINSET_ALLOWED,
  NIC_CARD_SUBTYPE_PINSET_ALLOWED_FOR_LOCKED,
  SDE,
  PERSO_SUBSTANCE,
  cardPersonalizationOptions.code,
];

export const CardAndPinMaintenance: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { productId } = useParams<{ id: string; productId: string }>();
  const [tenant] = useReduxState<Tenant>("tenant", {});

  const { persoList } = useGetPerso();

  const cardPersonalization = Form.useWatch<string>(cardPersonalizationOptions.code, form);
  const isVirtPhysV = Form.useWatch<string>(cardTypeOptions.code, form) === cardTypeOptions.options[0].value;
  const isSdeTrue = Form.useWatch<boolean>(SDE, form) === true;
  const isPersoNotO =
    Form.useWatch<string>(cardPersonalizationOptions.code, form) !== cardPersonalizationOptions.options[1].value;

  const relatedLinks = [
    {
      href: `/tenant/${tenant.id}/card-and-pin-maintenance`,
      label: "Card and PIN Maintenance - Global",
    },
  ];

  const { currentProduct: product, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const persoSubstanceValue = useMemo(
    () => product.productValues?.find(x => x.fieldCode === cardPersonalizationOptions.code)?.value,
    [product.productValues],
  );

  useHydrateForm(
    {
      form,
      entityFields: product?.productValues
        ? [
            ...(!persoSubstanceValue
              ? [
                  {
                    fieldCode: PERSO_SUBSTANCE,
                    value: "IDEMIA",
                  },
                  {
                    fieldCode: cardPersonalizationOptions.code,
                    value: persoSelectValue,
                  },
                ]
              : [
                  {
                    fieldCode: PERSO_SUBSTANCE,
                    value:
                      persoSubstanceValue === cardPersonalizationOptions.options[1].value
                        ? "IDEMIA"
                        : persoSubstanceValue,
                  },
                  {
                    fieldCode: cardPersonalizationOptions.code,
                    value:
                      persoSubstanceValue === cardPersonalizationOptions.options[1].value
                        ? persoSubstanceValue
                        : persoSelectValue,
                  },
                ]),
            ...product.productValues.filter(x => !(x.fieldCode === cardPersonalizationOptions.code)),
          ]
        : [],
      keys: {
        strings: stringsKeys,
      },
    },
    [product],
  );

  const onSubmit = (values: FormValues) => {
    if (values[PERSO_SUBSTANCE]) {
      values[cardPersonalizationOptions.code] = values[PERSO_SUBSTANCE];
    }
    delete values[PERSO_SUBSTANCE];

    [...stringsKeys].forEach(key => {
      if (!values[key]?.toString() && product?.productValues?.find(item => item.fieldCode === key)) {
        values[key] = "";
      }
    });

    void onUpdateProduct(values);
  };

  const validateNicCardSubtypeAutorenewDays = (value: StoreValue): Promise<string | void> => {
    if (value && (value < 0 || value > 90)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject<string>("Number of days must be equals to or lower than 90.");
    }

    if (value && value % 1 > 0) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject<string>("Enter a valid number of days. (no decimals)");
    }

    return Promise.resolve();
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Card and PIN Maintenance"
      submitHandler={onSubmit}
      size="md"
      formSize="md"
      submitLabel="Save"
      relatedLinks={relatedLinks}
      gap={24}
    >
      <NetworkForm.Radio
        formItemOptions={{
          name: cardTypeOptions.code,
          label: cardTypeOptions.name,
        }}
        radioList={cardTypeOptions.options}
      />

      {!isVirtPhysV && (
        <>
          <NetworkForm.Radio
            formItemOptions={{
              name: cardPersonalizationOptions.code,
              label: cardPersonalizationOptions.name,
              initialValue: cardPersonalizationOptions.options[0].value,
            }}
            radioList={cardPersonalizationOptions.options}
          />

          {cardPersonalization === cardPersonalizationOptions.options[1].value ? (
            <Alert>{OTHER_INFO_TEXT}</Alert>
          ) : cardPersonalization &&
            persoList?.length &&
            cardPersonalization === cardPersonalizationOptions.options[0].value ? (
            <Form.Item
              name={PERSO_SUBSTANCE}
              label={
                <FormItemLabel
                  label="Network International partners (Perso Bureau)"
                  code={cardPersonalizationOptions.code}
                />
              }
              rules={[
                {
                  required: true,
                  message: "Network International partners is required.",
                },
                {
                  validator: (_, value) => {
                    if (value === persoSelectValue) return Promise.reject();
                    return Promise.resolve();
                  },
                  message: "Network International partners is required.",
                },
              ]}
              initialValue="IDEMIA"
              className={styles["product-details-form-input"]}
            >
              <NetworkForm.Select
                optionList={persoList.map(listEntry => ({
                  label: listEntry.displayValue,
                  value: listEntry.value,
                }))}
              />
            </Form.Item>
          ) : null}
        </>
      )}

      <NetworkForm.Number
        formItemOptions={{
          name: NIC_CARD_SUBTYPE_EXP_MONTHS,
          label: "Number of months from card issuing till card expirations",
          className: styles["product-details-form-input"],
          rules: [
            { required: true, message: "Number of months is required!" },
            { type: "number", min: 12, message: "Number of months must be equal to or larger than 12." },
            { type: "number", max: 60, message: "Number of months must be equal to or lower than 60." },
          ],
        }}
        placeholder="Number of months"
      />

      <NetworkForm.Number
        formItemOptions={{
          name: NIC_CARD_SUBTYPE_AUTORENEW_DAYS,
          label: "Number of days before card expiration to initiate card renewal process",
          className: styles["product-details-form-input"],
          rules: [
            {
              validator(_, value) {
                return validateNicCardSubtypeAutorenewDays(value);
              },
            },
          ],
        }}
        placeholder="Number of days"
      />

      <NetworkForm.Select
        formItemOptions={{
          name: NIC_CARD_CARR_ADDRESS_LEV_PRIM,
          label: "Deliver primary cards and PINs",
          className: styles["product-details-form-input"],
          initialValue: "A",
        }}
        optionList={nicCardCarrAddressLevPrim}
      />

      <NetworkForm.Select
        formItemOptions={{
          name: NIC_CARD_CARR_ADDRESS_LEV_SUPPL,
          label: "Deliver supplementary cards and PINs",
          className: styles["product-details-form-input"],
          initialValue: tenant?.countryCode === "SA" ? "A" : "C",
          tooltip:
            tenant.countryCode === "SA"
              ? "According to SAMA requirements Cards and PINs must only be delivered to the primary account holder in case of prepaid supplementary cardholder"
              : null,
        }}
        optionList={nicCardCarrAddressLevSuppl}
      />

      <Space direction="vertical" size={16}>
        {!isVirtPhysV && (
          <NetworkForm.Switch
            formItemOptions={{
              name: REISS_PHYS,
              className: "m-b-0",
              label: REISS_PHYS_LABEL,
              valuePropName: "checked",
            }}
          />
        )}

        <NetworkForm.Switch
          formItemOptions={{
            name: NIC_PREEMB_AUTO_ACTIVATE,
            valuePropName: "checked",
            label: "Virtual cards auto-activation",
          }}
        />

        <NetworkForm.Switch
          formItemOptions={{
            name: NIC_CARD_SUBTYPE_PINSET_ALLOWED,
            valuePropName: "checked",
            rules: [{ required: true, message: "Pin Set is required!" }],
            label: "PIN set is allowed",
          }}
        />

        <NetworkForm.Switch
          formItemOptions={{
            name: NIC_CARD_SUBTYPE_PINSET_ALLOWED_FOR_LOCKED,
            valuePropName: "checked",
            rules: [{ required: true, message: "Change PIN is required!" }],
            label: "Change PIN allowed for locked plastics",
            tooltip: "This allows your customer to change card PIN prior to activating it",
          }}
        />

        {!isVirtPhysV && isPersoNotO && (
          <>
            <NetworkForm.Switch
              formItemOptions={{
                name: SDE,
                valuePropName: "checked",
                className: "m-b-0",
                label: SDE_LABEL,
                tooltip: SDE_TOOLTIP,
              }}
            />

            {isSdeTrue && <Alert>{SDE_INFO}</Alert>}
          </>
        )}
      </Space>
    </CustomFormWrapper>
  );
};
