/* eslint-disable @nrwl/nx/enforce-module-boundaries */

import { useCallback, useEffect } from "react";
import { Card, Col, Form, FormInstance, Row, Typography } from "antd";

import { FormValues } from "@ni/common/types";

import { NetworkForm } from "../FormInput";
import { FormItemLabel } from "../Labels";
import { TooltipInfo } from "../TooltipInfo";

import { predefinedColumns, RuleColumns } from "./constants";

import styles from "./styles.module.scss";

export type DynamicSwitchRule = {
  name: string;
  label: string;
  tooltip?: string;
  maxNumber: string;
  maxAmount: string;
  maxSingleAmount: string;
};

interface Props {
  form: FormInstance<FormValues>;
  name: string;
  title: string;
  tooltip?: string;
  columns?: RuleColumns[];
  currency: string;
  rules: DynamicSwitchRule[];
}

const DynamicSwitchForm = ({ form, name, title, tooltip, columns, currency, rules }: Props) => {
  const isMainSwitchEnabled = Form.useWatch<boolean>(name, form);

  const subSwitchesDisabled = useCallback(() => {
    const currentValues = form.getFieldsValue(rules.map(rule => rule.name)) as FormValues;
    return Object.values(currentValues)
      .filter(value => value !== undefined)
      .every(value => value === false);
  }, [form, rules]);

  useEffect(() => {
    const allDisabled = subSwitchesDisabled();
    if (!allDisabled) form.setFieldValue(name, true);
  }, [form, name, rules, subSwitchesDisabled]);

  const mainSwitchChangeHandler = (checked: boolean) => {
    const formValues = rules.reduce(
      (acc, rule) => {
        acc[rule.name] = checked;
        return acc;
      },
      {} as Record<string, boolean>,
    );
    form.setFieldsValue(formValues);
  };

  const subSwitchChangeHandler = (checked: boolean) => {
    const allDisabled = subSwitchesDisabled();
    if (checked) {
      if (!isMainSwitchEnabled) form.setFieldValue(name, true);
    } else if (allDisabled) form.setFieldValue(name, false);
  };

  return (
    <Card className={styles["dynamic-switch-form"]}>
      <Row align="middle" className={styles["dynamic-switch-form-header"]}>
        <Col>
          <NetworkForm.Switch
            formItemOptions={{
              name,
              label: title,
              tooltip: tooltip ?? undefined,
            }}
            onChange={mainSwitchChangeHandler}
          />
        </Col>
      </Row>

      <div className={styles["dynamic-switch-form-body"]}>
        <Row gutter={24}>
          <Col span={6} />

          {(columns ?? predefinedColumns).map(column => (
            <Col key={column.label} span={6}>
              <Typography.Text strong={true}>
                <TooltipInfo label={column.label} tooltipProps={{ title: column.tooltip }} />
              </Typography.Text>
            </Col>
          ))}
        </Row>

        {rules.map(rule => (
          <Row key={rule.name} align="middle" gutter={24} style={{ marginTop: 16 }}>
            <Col span={6}>
              <NetworkForm.Switch
                formItemOptions={{
                  name: rule.name,
                  label: rule.label,
                  tooltip: rule.tooltip,
                  valuePropName: "checked",
                }}
                onChange={subSwitchChangeHandler}
              />
            </Col>

            <Col span={6} className={styles["dynamic-switch-form-input"]}>
              <Form.Item dependencies={[rule.name]}>
                {() => (
                  <FormItemLabel code={rule.maxNumber}>
                    <NetworkForm.Number
                      formItemOptions={{
                        name: rule.maxNumber,
                      }}
                      min={0}
                      controls={true}
                      disabled={!form.getFieldValue(rule.name)}
                    />
                  </FormItemLabel>
                )}
              </Form.Item>
            </Col>

            <Col span={6} className={styles["dynamic-switch-form-input"]}>
              <Form.Item dependencies={[rule.name]}>
                {() => (
                  <FormItemLabel code={rule.maxAmount}>
                    <NetworkForm.Number
                      formItemOptions={{
                        name: rule.maxAmount,
                      }}
                      min={0}
                      addonAfter={currency}
                      disabled={!form.getFieldValue(rule.name)}
                    />
                  </FormItemLabel>
                )}
              </Form.Item>
            </Col>

            <Col span={6} className={styles["dynamic-switch-form-input"]}>
              <Form.Item dependencies={[rule.name]}>
                {() => (
                  <FormItemLabel code={rule.maxSingleAmount}>
                    <NetworkForm.Number
                      formItemOptions={{
                        name: rule.maxSingleAmount,
                      }}
                      min={0}
                      addonAfter={currency}
                      disabled={!form.getFieldValue(rule.name)}
                    />
                  </FormItemLabel>
                )}
              </Form.Item>
            </Col>
          </Row>
        ))}
      </div>
    </Card>
  );
};

export { DynamicSwitchForm };
