import { FC } from "react";
import { Alert, Form, FormInstance, Space, Typography } from "antd";

import { IJiraCommitForm } from "@ni/common/types";

import { NetworkForm } from "../FormInput";

export const JiraCommitForm: FC<{ form: FormInstance<IJiraCommitForm>; hideFiInput?: boolean }> = ({
  form,
  hideFiInput,
}: {
  form: FormInstance<IJiraCommitForm>;
  hideFiInput?: boolean;
}) => {
  return (
    <Form form={form} layout="vertical">
      <br />
      <NetworkForm.String
        formItemOptions={{
          name: "jiraTicketId",
          label: "Jira Ticket ID",
          tooltip: "This ensures your changes are aligned to project guidelines",
          rules: [
            { required: true, message: "Jira ticket id is required" },
            { pattern: /^[A-Z]+-\d+$/, message: "Invalid jira ticket id" },
          ],
        }}
        placeholder="Example, XXXX-000"
      />

      <NetworkForm.String
        formItemOptions={{
          name: "commitComment",
          label: "Additional Notes",
          tooltip: "This will be added to commit message for reviewer",
          rules: [{ required: true, message: "Additional notes is required" }],
        }}
        placeholder="Enter one line comment"
      />

      <NetworkForm.String
        formItemOptions={{
          name: "originBranch",
          label: "Origin Branch Name",
        }}
        placeholder="Origin Branch"
      />

      <NetworkForm.String
        formItemOptions={{
          name: "sourceBranch",
          label: "Source Branch Name",
          tooltip: "Feature branch name",
        }}
        placeholder="Source Branch"
      />

      <NetworkForm.String
        formItemOptions={{
          name: "destinationBranch",
          label: "Destination Branch Name",
        }}
        placeholder="Destination Branch"
      />

      {!hideFiInput && (
        <Alert
          message={
            <Space direction="vertical">
              <Typography.Paragraph>
                Please ensure, that FI ID is unique across all regions.
                <Typography.Link href="https://jira.network.ae/confluence/display/NICE/Standard+Product%3A+Customer+List">
                  {" click here"}
                </Typography.Link>
              </Typography.Paragraph>

              <NetworkForm.String
                formItemOptions={{
                  name: "fiCode",
                  label: "FI Code",
                }}
                placeholder="Enter one line comment"
              />
            </Space>
          }
        />
      )}
    </Form>
  );
};
